import React from "react"
import { Fade } from "react-awesome-reveal";
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout navSolid="true">
    <SEO title="404: Not found" />
    <section className="intro">
      <div className="container">
        <Fade triggerOnce>
          <h1>NOT FOUND</h1>
          <p className="lead">You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Fade>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
